<i18n>
{
  "en": {
    "purchase_button": "PURCHASE",
    "col_plan_name": "Plan",
    "col_plan_price": "Price",
    "col_plan_apply": " "
  },
  "ja": {
    "purchase_button": "お申し込み",
    "col_plan_name": "プラン名",
    "col_plan_price": "プラン料金",
    "col_plan_apply": "お申し込み"
  }
}
</i18n>

<template>
<div class="plan-compact">
  <div class="plan-compact-inner dml-plan">
    <div class="plan-section plan-section--title">
      <div>{{ $t('col_plan_name') }}</div>
      <div>{{ $t('col_plan_price') }}</div>
      <div>{{ $t('col_plan_apply') }}</div>
    </div>
    <div v-for="pkg in orderedPackages" :key="pkg.package_id" class="plan-section">
      <div class="plan-name">{{ pkg.tpl.description[locale] }}</div>
      <!-- original price, or if campaign price is the same as the original price -->
      <div class="plan-price" v-if="!pkg.tpl.price_original || (pkg.tpl.price_original && pkg.tpl.price_original === pkg.tpl.price)">
        {{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price }}{{ pkg.tpl.currency_symbol.suffix }}
        <span class="plan-note">{{ pkg.tpl.note.status[locale] }}</span>
      </div>
      <!-- if campaign price is different than original, show crossed-out original price and campaign price -->
      <div class="plan-price" v-if="pkg.tpl.price_original && pkg.tpl.price_original !== pkg.tpl.price">
        <strike>{{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price_original }}{{ pkg.tpl.currency_symbol.suffix }}</strike>
        <span class="plan-price discount">{{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price }}{{ pkg.tpl.currency_symbol.suffix }}</span>
        <span class="plan-note">{{ pkg.tpl.note.status[locale] }}</span>
      </div>
      <div class="plan-cta"><button class="button-fill button-large" :class="{'is-disabled': !pkg.user_type_visibility[userType]}" @click="clickPurchaseButton(pkg.label)">{{ $t('purchase_button') }}</button></div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import UAParser from 'ua-parser-js';
import orderBy from 'lodash/orderBy';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    packageType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      packageList: [],
      signerUrls: {},
      deviceType: 'desktop',
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    orderedPackages() {
      return orderBy(this.packageList, 'tpl.display_order');
    },
    userType() {
      return this.$store.getters['user/typeStr'];
    },
  },
  created() {
    this.getPackagesAndUrls();
  },
  methods: {
    async getPackagesAndUrls() {
      // get device type
      const parser = new UAParser();
      const device = parser.getDevice();
      // UAParser returns separate 'mobile' and 'tablet' device types (and a few others). Fixes a
      // bug we had where packages, were not appearing for iPads since those were returning 'tablet'
      // device types here, but the package list only designates 'mobile' types or 'desktop'. I
      // doubt Sales will be requesting specific packages for 'tablet' (unless they want to track
      // those separately) so we're just folding all devices as 'mobile' here:
      this.deviceType = (device.type) ? 'mobile' : 'desktop';

      // fetch join and campaign package lists
      const bfAPI = new BifrostAPI();
      const packageList = await bfAPI.getJoinPackages();
      const campaignPackageList = await bfAPI.getCampaignPackages();

      // walk thru campaign packages and override corresponding package in this.packageList
      if (Object.prototype.hasOwnProperty.call(campaignPackageList, this.currency)) {
        for (let cpl = 0; cpl < campaignPackageList[this.currency].length; cpl += 1) {
          for (let pl = 0; pl < packageList[this.currency].length; pl += 1) {
            if (packageList[this.currency][pl].label === campaignPackageList[this.currency][cpl].label) {
              // save original price
              const originalPrice = packageList[this.currency][pl].tpl.price;

              // override original package with campaign package
              packageList[this.currency][pl] = campaignPackageList[this.currency][cpl];

              // restore original price
              packageList[this.currency][pl].tpl.price_original = originalPrice;
              break;
            }
          }
        }
      }

      // get packages to display for this user/page/locale/etc
      // we used to have 'item.user_type_visibility[this.userType]' in these filters but are now
      // including it in the package list and just disabling the button on the template side
      this.packageList = packageList[this.currency].filter(
        item => item.page_visibility[this.packageType]
          && item.device_visibility[this.deviceType]
          && item.locale_visibility[this.locale],
      );

      // first build signerUrls object using fallback URLs (in case we get nothing from Signer)
      const fallbackUrlsArr = this.packageList.map((item) => {
        const obj = {};
        obj[item.label] = item.fallback_url;
        return obj;
      });
      const fallbackUrls = Object.assign({}, ...fallbackUrlsArr);
      this.signerUrls = fallbackUrls;

      // now get signer urls
      this.$analytics.getClientId().then((clientId) => {
        bfAPI.getSignerURLs(clientId).then((response) => {
          if (response.status === 200) {
            if (Object.prototype.hasOwnProperty.call(response.data.data, 'join')) {
              this.signerUrls = response.data.data.join;
              if (Object.prototype.hasOwnProperty.call(response.data.data, 'campaign')) {
                // overwrite signerUrl urls with campaign shooter urls
                Object.keys(response.data.data.campaign).forEach((label) => {
                  this.$set(this.signerUrls, label, response.data.data.campaign[label]);
                });
              }
            }
          }
        });
      });
    },
    clickPurchaseButton(label) {
      // note: we can't do any calls in this function that return promises in this function.
      // otherwise browsers will likely block the new window from opening, thinking it's a popup.
      // browsers will open 'window.open()' urls if it directly comes from a user click event
      this.$analytics.trackEvent('Upgrade', 'Upgrade Page Purchase Button', label);
      let purchaseUrl = this.signerUrls[label];

      // append language param to the purchase URL
      purchaseUrl += label.includes('-en')
        ? '&lang=en'
        : '&lang=jp';
      window.open(purchaseUrl, '_blank');
    },
  },
  watch: {
    // refresh table when user type (or login state) changes
    userType(newVal, oldVal) {
      if (newVal !== oldVal) this.getPackagesAndUrls();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div>
    <div v-for="(banners, groupName) in badminBannerGroups" :key="groupName">
      <!-- fixed banner: full width -->
      <div v-if="banners[0].banner_type == 'full_width'">
        <div
          class="banner banner-layered"
          :style="{ 'background-image': 'url(/dyn/dla/images/' + banners[1].img_path + ')' }"
        >
          <div>
            <img
              class="banner-image"
              :src="`/dyn/dla/images/${banners[0].img_path}`"
              @click="copyToClipboard(banners[0].coupon_code)"
              v-sfw
            />
          </div>
        </div>
      </div>

      <!-- fixed banner -->
      <div v-if="banners[0].banner_type == 'fixed'">
        <div class="hero" v-for="banner in banners" :key="banner.display_order">
          <img
            :src="`/dyn/dla/images/${banner.img_path}`"
            class="banner-image"
            @click="copyToClipboard(banner.coupon_code)"
            v-sfw
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Badmin from '@/assets/js/services/Badmin';

export default {
  data() {
    return {
      badminBannerGroups: {},
    };
  },
  async beforeCreate() {
    const badminSvc = new Badmin(
      this.$i18n.locale,
      this.$store.getters['user/typeID'],
    );
    this.badminBannerGroups = await badminSvc.getBanners('ddl');
  },
  computed: {
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    async userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badminSvc = new Badmin(
          this.$i18n.locale,
          this.$store.getters['user/typeID'],
        );
        this.badminBannerGroups = await badminSvc.getBanners('ddl');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<i18n>
{
  "en": {
    "browser_title": "DDL (Daily Download Limit Plan)"
  },
  "ja": {
    "browser_title": "ダウンロード増量プラン"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div class="dml">
      <ddl-image />

      <!-- japanese text -->
      <section v-if="locale == 'ja'">
        <div class="contents">
          <div class="heading">
            <h2>ダウンロード増量プランとは</h2>
          </div>
          <p>ダウンロード増量プランとは、ダウンロード転送量を追加購入することにより、1日あたりのダウンロード転送量上限を大幅UPさせる定額サービスです。</p>
          <ul class="list-style list-style--disc">
            <li>30GB増量 (30日間 / 1日1GB増)</li>
            <li>60GB増量 (30日間 / 1日2GB増)</li>
            <li>90GB増量 (30日間 / 1日3GB増)</li>
            <li>無制限</li>
          </ul>
          <p>の全4プランの中から好みに合わせたプランをお選びいただけます。</p>
        </div>
      </section>
      <section v-if="locale == 'ja'">
        <div class="contents">
          <div class="heading">
            <h2>プラン比較</h2>
          </div>
          <div class="dml-comparison">
            <div class="comparison-description">
              <p>ダウンロード増量プランをご購入いただくことにより、30GB増量プランで最大1.17倍、60GB増量プランで最大1.3倍、90GB増量プランで最大1.5倍、無制限プランで∞倍まで1日のダウンロード転送量の上限を引き上げることができます。</p>
            </div>
            <div class="comparison-figure">
              <div class="figure-item">
                <div class="figure-graph figure-graph--unlimited">
                  <span class="figure-note">∞</span>
                </div>
                <div class="figure-title">無制限プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--90">
                  <span class="figure-note">1.5x</span>
                </div>
                <div class="figure-title">90GB増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--60">
                  <span class="figure-note">1.3x</span>
                </div>
                <div class="figure-title">60GB増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--30">
                  <span class="figure-note">1.17x</span>
                </div>
                <div class="figure-title">30GB増量プラン</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--0"></div>
                <div class="figure-title">増量プランなし</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="locale == 'ja'">
        <div class="contents">
          <div class="heading">
            <h2>お申し込み</h2>
          </div>
          <package-table currency="USD" package-type="ddl" />
          <ul class="list-style list-style--disc">
            <li>ダウンロード増量プランの有効期限は、一本道の会員期間終了に伴い自動で終了します。</li>
            <li>ダウンロード増量プランは自動継続方式となっておりますので、30日ごとに自動課金されます。</li>
            <li>
              ダウンロード増量プランの退会をご希望の場合はD2Passマイアカウントからの退会手続きが必要です。退会手続きは
              <a
                href="https://www.d2pass.com/account/download/sitelist?NetiFL=1"
                target="_blank"
                rel="noopener"
              >こちら »</a>
            </li>
          </ul>
        </div>
      </section>
      <!-- /japanese text -->

      <!-- english text -->
      <section v-if="locale == 'en'">
        <div class="contents">
          <div class="heading">
            <h2>About Daily Download Limit Plan</h2>
          </div>
          <p>Daily Download Limit Plan is a service that increases the maximum transfer limit per day by purchasing additional amount. We currently offer following 4 plans.</p>
          <ul class="list-style list-style--disc">
            <li>+30GB Plan (30 days / +1GB per day)</li>
            <li>+60GB Plan (30 days / +2GB per day)</li>
            <li>+90GB Plan (30 days / +3GB per day)</li>
            <li>Unlimited Plan (30 days)</li>
          </ul>
        </div>
      </section>
      <section v-if="locale == 'en'">
        <div class="contents">
          <div class="heading">
            <h2>Plan comparison</h2>
          </div>
          <div class="dml-comparison">
            <div class="comparison-description">
              <p>By purchasing the Daily Download Limit Plan, you can increase the maximum transfer limit for 1 day up to x1.17 with 30GB plan, up to x1.3 with 60GB plan, up to x1.5 with 90GB plan, and x∞ with unlimited plan.</p>
            </div>
            <div class="comparison-figure">
              <div class="figure-item">
                <div class="figure-graph figure-graph--unlimited">
                  <span class="figure-note">∞</span>
                </div>
                <div class="figure-title">Unlimited</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--90">
                  <span class="figure-note">1.5x</span>
                </div>
                <div class="figure-title">+90GB Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--60">
                  <span class="figure-note">1.3x</span>
                </div>
                <div class="figure-title">+60GB Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--30">
                  <span class="figure-note">1.17x</span>
                </div>
                <div class="figure-title">+30GB Plan</div>
              </div>
              <div class="figure-item">
                <div class="figure-graph figure-graph--0"></div>
                <div class="figure-title">Default</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="locale == 'en'">
        <div class="contents">
          <div class="heading">
            <h2>Purchase</h2>
          </div>
          <package-table currency="USD" package-type="ddl" />
          <ul class="list-style list-style--disc">
            <li>Daily Download Limit Plan will be automatically terminated when your 1pondo's membership expires.</li>
            <li>Daily Download Limit Plan will be automatically renewed and charged every 30 days.</li>
            <li>
              If you want to cancel your Daily Download Limit Plan, you will need to cancel from D2Pass My Account page.
              <a
                href="https://www.d2pass.com/account/download/sitelist?NetiFL=1&lang=en"
                target="_blank"
                rel="noopener"
              >
                Click here
                <i class="iconf-ex-link"></i>
              </a> to cancel.
            </li>
          </ul>
        </div>
      </section>
      <!-- /english text -->
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import DDLImage from '@/components/ddl/ddlImage.vue';
import PackageTable from '@/components/ddl/packageTable.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'ddl-image': DDLImage,
    'package-table': PackageTable,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
</style>
